exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kas-ir-rure-tsx": () => import("./../../../src/pages/kas-ir-rure.tsx" /* webpackChunkName: "component---src-pages-kas-ir-rure-tsx" */),
  "component---src-pages-mediciniskie-pakalpojumi-un-tehnisko-paliglidzeklu-noma-tsx": () => import("./../../../src/pages/mediciniskie-pakalpojumi-un-tehnisko-paliglidzeklu-noma.tsx" /* webpackChunkName: "component---src-pages-mediciniskie-pakalpojumi-un-tehnisko-paliglidzeklu-noma-tsx" */),
  "component---src-pages-mobila-paliativa-aprupe-majas-tsx": () => import("./../../../src/pages/mobila-paliativa-aprupe-majas.tsx" /* webpackChunkName: "component---src-pages-mobila-paliativa-aprupe-majas-tsx" */),
  "component---src-pages-paliativas-aprupes-klinika-tsx": () => import("./../../../src/pages/paliativas-aprupes-klinika.tsx" /* webpackChunkName: "component---src-pages-paliativas-aprupes-klinika-tsx" */),
  "component---src-pages-rehabilitacijas-programma-tsx": () => import("./../../../src/pages/rehabilitacijas-programma.tsx" /* webpackChunkName: "component---src-pages-rehabilitacijas-programma-tsx" */),
  "component---src-pages-veselibas-centrs-liepaja-tsx": () => import("./../../../src/pages/veselibas-centrs-liepaja.tsx" /* webpackChunkName: "component---src-pages-veselibas-centrs-liepaja-tsx" */)
}

